import React from 'react';

import '../style.scss';
import Helmet from './helmet';
import Footer from './footer';
import Navbar from './navbar';

const Layout = ({ children, previous, next }) => (
	<div>
	<Helmet />
	<Navbar />
    {children}

		<Footer previous={previous} next={next} />
	</div>
);

export default Layout;

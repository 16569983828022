import React from 'react'
import { FaLinkedin, FaEnvelope, FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby';

const Footer = ({previous, next}) => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
	          linkedin
	          mail
          }
        }
      }
    `}
    render={data => (
      <footer id="footer" className='footer center' style={{background: "#123a55", color: "white"}}>
        <div className='content has-text-centered'>
         <div className="columns">
	{previous &&
          <div className="column is-6">
           <Link className="button is-medium is-uppercase home_buttons" to={previous}><FaArrowLeft style={{color: "#123a55"}} /></Link>
          </div>
	}
	{next &&
          <div className="column is-6">
           <Link className="button is-medium is-uppercase home_buttons" to={next}><FaArrowRight style={{color: "#123a55"}}/></Link>
          </div>
	}
         </div>

          <p>
            Soyons réseaux-nables, {' '}
            <a href={data.site.siteMetadata.linkedin}> entrons en contact !</a>
          </p>

           <article className='media center' style={{marginTop: "12px", marginBottom: "12px"}}>
            <span className='icon'>
              <a href={data.site.siteMetadata.linkedin}>
                <FaLinkedin size='fa-2x' color='#0E76A8' />
              </a>
            </span>
	   &nbsp;
            <span className='icon'>
              <a href={"mailto:" + data.site.siteMetadata.mail}>
                <FaEnvelope size='fa-2x' color='yellow' />
              </a>
            </span>
           &nbsp;
           </article>

          <p>
            2021 © Morgan Bouvet
          </p>
        </div>
      </footer>
    )}
  />
)

export default Footer

import React from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { GoHome } from 'react-icons/go';

import gatsbyLogo from '../../images/gatsby-icon.png';
import profilPicture from '../../images/profil_picture.jpg';
import logoWoptimoSymbol from '../../images/logo_woptimo_symbol.png';
import { Link } from '@reach/router';
import { steelblue } from 'color-name';

const Navbar = () => (
	<div className="hero-head">
		<nav className="navbar" style={{backgroundColor: "#3fa1da", display: "inline-flex !important"}}>
			<div className="container">
				<div className="navbar-brand">
					<Link className="navbar-item" to="/">
						
						<img src={profilPicture} alt="Photo-de-Profil" style={{borderRadius:"50%"}} />
						<img src= {logoWoptimoSymbol} alt="Logo-Woptimo" />
					</Link>
				</div>
				<div id="navbarMenuHeroA" style={{alignContent: "flex-end", marginLeft: "auto"}}>
					<div className="navbar-end">
						<span className="navbar-item">
							<a
								className="button is-danger is-inverted"
								href={"/cv.pdf"}
							>
								<span className="icon">
									<FaFilePdf size="fa-2x" />
								</span>
								<span>Télécharger CV</span>
							</a>
						</span>
					</div>
				</div>
			</div>
		</nav>
	</div>
);

export default Navbar;
